import React from "react";
import classes from "./style.module.css";
import HeaderLogo from "../../assets/images/HeaderLogo.svg";
const index = () => {
  return (
    <div
      className="d-flex justify-content-center"
      // style={{ position: "relative" }}
    >
      <div className={classes["header-logo-wrapper"]}>
        <img src={HeaderLogo} alt="Logo" />
      </div>
    </div>
  );
};

export default index;
