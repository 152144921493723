import React, { useState } from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/settings.svg";
import Logo from "../../assets/images/DexioprotocolWallet.svg";
import Add from "../../assets/icons/add.svg";
import Import from "../../assets/icons/import.svg";
import Settings from "../../assets/icons/settiongs.svg";
import Exit from "../../assets/icons/Exit.svg";
import Return from "../../assets/icons/return.svg";
import {Modal} from "antd"
import { useHistory } from "react-router-dom";
import {useSelector} from "react-redux";
import modalStyle from "./modalStyle.module.css"
import SecretPhrase from "./SecretPhrase";
import {
  AuthLogoHeader,
  BorderButton,
  Input,
  Layout,
  Header,
  Option,
} from "../../Components";

const Index = () => {
  const history = useHistory();
    const [phraseModal, setPhraseModal] = useState(false);
  const activeWallet = useSelector(
    (state) => state.wallet.activeWallet
  );
  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "justify-content-between"]}
    >
      <>
        <div>
          <Header title="My Account" />
          <div className={[classes["wrapper"], "mt-5"].join(" ")}>
            <Modal
              title="Basic Modal"
              visible={phraseModal}
              onCancel={() => {
                setPhraseModal(false);
              }}
              footer={false}
              closable={false}
              className={modalStyle.customModal}
              width={588}
              centered
            >
              <SecretPhrase
                selectedWallet={activeWallet}
                onCancel={() => setPhraseModal(false)}
              />
            </Modal>
            <div>
              {/* 
              <Option
                title="Import Account"
                Image={Import}
                onClick={() => {
                  history.push({
                    pathname: "/secretphrase",
                    state: { importWallet: true },
                  });
                }}
              />

             */}
              {/* <Option
                title="Settings"
                Image={Settings}
                onClick={() => {
                  history.push("/mysettings");
                }}
              /> */}
              <Option title="Show Seed Phrase" Image={Add} onClick={() => {setPhraseModal(true)}} />
              <Option
                onClick={() => {
                  window.location.replace(
                    "app.dexihunter.wallet://app.dexihunter.wallet"
                  );
                }}
                title="Exit"
                Image={Exit}
              />
              {/* <Option title="Return to Wallet" Image={Return} /> */}
              <img src={Logo} alt="" />
            </div>
          </div>
        </div>

        <div
          className="pb-4 d-flex justify-content-around mt-5"
          style={{ minWidth: "100%" }}
        >
          <BorderButton
            fontSize={14}
            width="9rem"
            onClick={() => history.push("/main")}
          >
            Wallet
          </BorderButton>

          <button className={classes["wallet-button"]}>Settings</button>
        </div>
      </>
    </Layout>
  );
};

export default Index;
