import React, { useState, useRef, useEffect } from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/Account.svg";
import Down from "../../assets/icons/Down.svg";

import { BorderButton, InternalHeader, Layout } from "../../Components";
import { useSelector, useDispatch } from "react-redux";
import { setBalances, setNftBalances } from "store/actions";
import _ from "lodash";
import { setActiveWallet, setTransactions } from "store/actions/wallet";
import {
  getUserSpecificBalances,
  getUserTransactions,
  getAllPublicKeys,
  hunterTokenList,
} from "dexio-sdk";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import config from "helpers/config";
import { isCompositeComponent } from "react-dom/test-utils";
import { message } from "antd";

const Index = () => {
  const [isVisibleAccountOptions, setIsVisibleAccountOptions] = useState(false);
  const location = useLocation();

  const history = useHistory();
  const timerInterval = useRef(null);
  const dispatch = useDispatch();

  let [totalBalance, setTotalBalance] = useState(0);

  const activeWallet = useSelector((state) => state.wallet.activeWallet);
  const wallets = useSelector((state) => state.wallet.wallet);

  const balances = useSelector((state) => state.balances.balances);

  const tokens = useSelector((state) => state.balances.tokens);

  const activeWalletRef = useRef({});
  const balancesRef = useRef(balances);

  useEffect(() => {
    balancesRef.current = balances;
  }, [balances]);

  const [loading, setLoading] = useState(true);

  const saveAddressOnBackend = async () => {
    if (localStorage.getItem("backend_token")) {
      let token = localStorage.getItem("backend_token");

      const allPublicKeys = await getAllPublicKeys(
        activeWallet,
        hunterTokenList
      );

      // console.log(allPublicKeys, "all keys");

      await axios({
        url: `${config.hunter_backend}/users/store_addresses`,
        data: {
          token,
          addresses: allPublicKeys,
        },
        method: "POST",
      })
        .then((res) => {
          console.log("addresses saved", res.data);
          // alert("addresses saved");
        })
        .catch((err) => {
          console.log("error saving addresses", err);
          // alert("error saving address", err);
        });
    }
  };

  useEffect(() => {
    localStorage.setItem("tokens", JSON.stringify(tokens));

    // load nft tokens stored in local storage
    let nfts = JSON.parse(
      localStorage.getItem(`nfts${activeWalletRef.current.address}`) || "[]"
    );
    dispatch(setNftBalances(nfts, activeWalletRef.current.address));
    saveAddressOnBackend();

    return () => {
      clearInterval(timerInterval.current);

      timerInterval.current = "";
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      let tempActiveAddress = activeWalletRef.current.address;

      let userTokens = [];

      userTokens = [...hunterTokenList];

      let tempBalances = [];

      for (let i = 0; i < userTokens.length; i++) {
        getUserSpecificBalances(activeWallet, [userTokens[i]]).then(
          (singleBalance) => {
            if (
              balancesRef.current.find((item) => item.id == singleBalance[0].id)
            ) {
              let tempSecond = balancesRef.current.map((item) => {
                if (item.id == singleBalance[0].id) {
                  return {
                    ...item,
                    balance: singleBalance[0].balance,
                    usdBalance: singleBalance[0].usdBalance,
                    usdPrice: singleBalance[0].usdPrice,
                  };
                } else {
                  return item;
                }
              });
              dispatch(
                setBalances(tempSecond, activeWalletRef.current.address)
              );
            } else {
              dispatch(
                setBalances(
                  [...balancesRef.current, singleBalance[0]],
                  activeWalletRef.current.address
                )
              );
            }
          }
        );

        // tempBalances.push(tempBal[0]);
      }

      // tempBalances = tempBalances.sort((a, b) => {
      //   return b.usdBalance - a.usdBalance;
      // });
      // if (tempActiveAddress == activeWalletRef.current.address)
      //   dispatch(setBalances(tempBalances, activeWalletRef.current.address));

      localStorage.setItem("local_balances", JSON.stringify(tempBalances));

      const bscNftsResponse = await fetch(`https://deep-index.moralis.io/api/v2/${activeWalletRef.current.address}/nft?chain=bsc%20testnet&format=decimal`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-API-Key': process.env.REACT_APP_MORALIS_KEY,
        }
      });
      const bscNfts = await bscNftsResponse.json();
      const nftBalances = bscNfts.result;

      if (tempActiveAddress == activeWalletRef.current.address) {
        dispatch(setNftBalances(nftBalances, activeWalletRef.current.address));
      }
      setLoading(false);

      let transactions = await getUserTransactions(tempActiveAddress);
      dispatch(setTransactions(transactions));
    }

    if (activeWallet.address !== activeWalletRef.current.address) {
      activeWalletRef.current = activeWallet;
      fetchData();
      if (timerInterval.current) clearInterval(timerInterval.current);
      timerInterval.current = setInterval(async () => {
        console.log("++++++++++++++setInterval");
        fetchData();
      }, 60000);
    }
    if (localStorage.getItem("local_balances"))
      dispatch(
        setBalances(
          JSON.parse(localStorage.getItem("local_balances")),
          activeWalletRef.current.address
        )
      );
  }, [activeWallet, tokens]);

  useEffect(() => {
    let tempTotal = 0;
    balances.map((item) => {
      if (item.usdBalance) tempTotal += item.usdBalance;
    });
    setTotalBalance(tempTotal);
  }, [balances]);

  let tempBal = balances?.sort((a, b) => b.usdBalance - a.usdBalance);

  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "align-items-center"]}
    >
      <>
        <InternalHeader />
        <div className={[classes["account-box"], "py-3", "mt-3"].join(" ")}>
          <div
            className={["d-flex", "flex-column", "align-items-center"].join(
              " "
            )}
          >
            <div className={classes["account-internal-box"]}>
              <div className={classes["account-type-dropdown-wrapper"]}>
                {/* <div
                  className={[
                    classes["account-type-dropdown-select-wrapper"],
                    "d-flex",
                    "align-items-center",
                  ].join(" ")}
                  // onClick={() => setIsVisibleAccountOptions((ps) => !ps)}
                >
                  <p className="mb-0">{activeWallet.name}</p>
                  <div className={classes["account-select-down-icon-wrapper"]}>
                    <img src={Down} alt="Down" />
                  </div>
                </div> */}
                {isVisibleAccountOptions && (
                  <div
                    className={[
                      classes["account-type-dropdown-option-wrapper"],
                      "align-items-center",
                      "flex-column",
                      "d-flex",
                    ].join(" ")}
                    onClick={() => setIsVisibleAccountOptions((ps) => !ps)}
                  >
                    <div className={classes["assets-text-wrapper-top"]}>
                      <p className="mb-0">Assets</p>
                    </div>

                    {wallets?.map((item, i) => (
                      <div
                        key={i}
                        className={classes["list-options-wrapper"]}
                        onClick={() => dispatch(setActiveWallet(item))}
                      >
                        <p className="mb-0">{item.name}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div
                className={[
                  "d-flex",
                  "justify-content-between",
                  classes["rest-items"],
                ].join(" ")}
              >
                <div className="d-flex align-items-center">
                  <p className={[classes["my-assets-title"], "mb-0"].join(" ")}>
                    My Assets ($)
                  </p>
                </div>
                <p className={[classes["my-assets-amount"], "mb-0"].join(" ")}>
                  {`${new Intl.NumberFormat("en-US").format(totalBalance)}`}
                </p>
              </div>
              <div
                className={[
                  "d-flex",
                  "justify-content-center",
                  classes["send-recieve-row"],
                ].join(" ")}
              >
                <p
                  className="mb-0 "
                  style={{
                    padding: "0 20px",
                  }}
                  onClick={() => {
                    history.push("/send");
                  }}
                >
                  Send
                </p>
                <p
                  className={["mb-0", classes["recieve-para"]].join(" ")}
                  style={{ borderRight: 0 }}
                  onClick={() => {
                    history.push("/receive");
                  }}
                >
                  Receive
                </p>
                <p
                  className={["mb-0", classes["recieve-para"]].join(" ")}
                  style={{ borderRight: 0 }}
                  onClick={() => {
                    history.push("/nfts");
                  }}
                >
                  NFTS
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={[classes["asset-list-box"], "py-3", "mt-3"].join(" ")}>
          <div className={classes["asset-title-wrapper"]}>
            <p className="mb-0">Assets</p>
          </div>
          <div className={classes["asset-list-wrapper"]}>
            {tempBal.map((balance) => (
              <div
                className={[
                  "d-flex",
                  "align-items-center",
                  "justify-content-between",
                  classes["asset-list-item-wrapper"],
                ].join(" ")}
                onClick={() => {
                  history.push({
                    pathname: "/detail",
                    state: balance,
                  });
                }}
              >
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      src={
                        balance
                          ? balance.logo
                          : "http://localhost:8000/image.png"
                      }
                      style={{ width: "32px", height: "32px" }}
                    />
                  </div>
                  <div className="mx-2">
                    <p className={[classes["asset-label"], "mb-0"].join(" ")}>
                      {balance.name.toLocaleUpperCase()} (
                      {balance.symbol.toUpperCase()})
                    </p>
                    <small className="ml-4 mb-0" style={{ color: "white" }}>
                      {balance.type && balance.type !== "null"
                        ? balance.type
                        : ""}
                    </small>
                  </div>
                </div>
                <div style={{ textAlign: "right", minWidth: 75 }}>
                  <p className={[classes["asset-value"], "mb-0"].join(" ")}>
                    $
                    {balance?.usdBalance
                      ? new Intl.NumberFormat("en-US").format(
                          balance?.usdBalance
                        )
                      : "0.00"}
                  </p>
                  <p className={[classes["asset-price"], "mb-0"].join(" ")}>
                    {balance.balance
                      ? parseFloat(balance.balance).toFixed(
                          balance.priceDecimals
                        )
                      : 0.0}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>

      <div
        className="pb-4 d-flex justify-content-around mt-5"
        style={{ minWidth: "100%" }}
      >
        <button className={classes["wallet-button"]}>Wallet</button>
        <BorderButton
          fontSize={14}
          width="9rem"
          onClick={() => {
            history.push("/settings");
          }}
        >
          Settings
        </BorderButton>
      </div>
    </Layout>
  );
};

export default Index;
