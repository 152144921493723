import {
  Account,
  Transfer,
  CreatePassword,
  Disclaimer,
  SecretPhrase,
  Send,
  SignupOptions,
  Settings,
  MyAccount,
  ManageWallets,
  AccountSettings,
  Receive,
  DetailPage,
  TransactionsPage,
  NftPage,
  NftDetailPage,
} from "./Containers";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.css";
// test
import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  const activeWallet = useSelector((state) => state.wallet.activeWallet);
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/createpassword" component={CreatePassword} />
          <Route exact path="/secretphrase" component={SecretPhrase} />
          <Route exact path="/disclaimer" component={Disclaimer} />
          <Route exact path="/wallet-detail" component={AccountSettings} />
          <Route exact path="/main" component={Account} />
          <Route exact path="/mysettings" component={Settings} />
          <Route exact path="/settings" component={MyAccount} />
          <Route exact path="/receive" component={Receive} />
          <Route exact path="/send" component={Send} />
          <Route exact path="/transfer" component={Transfer} />
          <Route exact path="/manage-wallet" component={ManageWallets} />
          <Route exact path="/detail" component={DetailPage} />
          <Route exact path="/nfts" component={NftPage} />{" "}
          <Route exact path="/nft-detail" component={NftDetailPage} />
          <Route exact path="/transactions" component={TransactionsPage} />
          <Route exact path="/" component={SignupOptions} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
