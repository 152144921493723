import React from "react";
import BackgroundImage from "../../assets/backgrounds/Account.svg";
import { Header, Layout } from "../../Components";
import { useSelector } from "react-redux";
import NftCardItem from "../../Components/NftCardItem";
import classes from "./style.module.css";

const Index = () => {
  const nfts = useSelector((state) => state.balances.nftBalances);

  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "align-items-center"]}
    >
      <>
        <Header title="Nfts" varriant={false} />

        <div className="container">
          <div className={[classes["asset-list-box"], "mt-3"].join(" ")}>
            <div className="row border-0 p-3 m-0">
              {nfts?.map((nft, index) => (
                <NftCardItem
                  data={nft}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Index;
