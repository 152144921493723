import React from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/settings.svg";
import Logo from "../../assets/images/DexioprotocolWallet.svg";
import RigthArrow from "../../assets/icons/rigthArrow.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AuthLogoHeader,
  BorderButton,
  Input,
  Layout,
  Header,
  Option,
} from "../../Components";
const Index = () => {
  const wallets = useSelector((state) => state.wallet.wallet);
  const history = useHistory();
  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "justify-content-between"]}
    >
      <>
        <div>
          <Header title="Manage Wallets" />
          <div className={[classes["wrapper"], "mt-5"].join(" ")}>
            <div>
              {wallets.map((item) => (
                <Option
                  title={item.name}
                  Image={RigthArrow}
                  onClick={() => {
                    history.push({
                      pathname: "/wallet-detail",
                      state: item,
                    });
                  }}
                />
              ))}
              <img src={Logo} alt="" />
            </div>
          </div>
        </div>
        <div
          className="pb-4 d-flex justify-content-around mt-5"
          style={{ minWidth: "100%" }}
        >
          {/* <button className={classes["wallet-button"]}>Wallet</button>
          <BorderButton fontSize={14} width="9rem">
            Settings
          </BorderButton> */}
        </div>
      </>
    </Layout>
  );
};

export default Index;
