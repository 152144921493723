import React from "react";
import classes from "./style.module.css";
import TransactionIcon from "../../assets/icons/transactionIcon.png";
import Back from "../../assets/icons/Back.svg";
import Wallet from "../../assets/icons/Wallet.svg";
import HeaderSmallLogo from "../../assets/images/HeaderSmallLogo.svg";
import { useHistory } from "react-router-dom";
const Index = ({ showBack, type }) => {
  const history = useHistory();

  return (
    <div
      className={[
        classes["header-wrapper"],
        "d-flex",
        "justify-content-between",
        "align-items-center",
      ].join(" ")}
    >
      <div className="d-flex">
        <div
          onClick={() => {
            if (window.location.pathname == "/detail")
              history.goBack()
            else
              window.location.replace(
                "app.dexihunter.wallet://app.dexihunter.wallet"
              );
          }}
          style={{ marginRight: "20px" }}
        >
          <img src={Back} alt="Back" />
        </div>
        {/* <div
          onClick={() => {
            history.push({
              pathname: "/secretphrase",
              state: { importWallet: true },
            });
          }}
        >
          <img src={Wallet} alt="Wallet" />
        </div> */}
      </div>
      <div style={{ marginLeft: "-40px" }}>
        <img src={HeaderSmallLogo} alt="Logo" />
      </div>
      <div
        onClick={() => {
          history.push("/transactions");
        }}
      >
        <img src={TransactionIcon} alt="Scan" />
      </div>
      {type && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => {
              history.push({
                pathname: "/secretphrase",
                state: { importWallet: true },
              });
            }}
          >
            <img src={Wallet} alt="Wallet" />
          </div>
          <div
            onClick={() => {
              history.push({
                pathname: "/secretphrase",
                state: { importWallet: true },
              });
            }}
          >
            <img src={Wallet} alt="Wallet" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
