import React, { useEffect } from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/SignupOption.svg";
import Wallet from "../../assets/images/Wallet.svg";
// import Button from "../../assets/images/button.svg";
import { BorderButton, Layout } from "../../Components";
import { useHistory, useLocation } from "react-router-dom";
import {message} from "antd"

const Index = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let password = localStorage.getItem("dexiPassword");

    if (localStorage.getItem("dexiWallet") && password) {
      history.push({
        pathname: "/createpassword",
        state: {
          checkPassword: true,
          passwordExists: true,
        },
      });
    }

    if (location?.search && location.search.includes("?token=")) {
      let token = location.search.replace("?token=", "");
      localStorage.setItem("backend_token", token);
    }


  }, []);

  const onCreateAccount = () => {
    if (localStorage.getItem("disclaimerAccepted") == null) {
      history.push({
        pathname: "/disclaimer",
        state: { createWallet: true },
      });
      return;
    }

    if (localStorage.getItem("dexiPassword"))
      history.push({
        pathname: "/createpassword",
        state: { passwordExists: true },
      });
    else
      history.push({
        pathname: "/createpassword",
        state: { createWallet: true },
      });
  };

  const onImportWallet = () => {
    if (localStorage.getItem("disclaimerAccepted") == null) {
      history.push({
        pathname: "/disclaimer",
        state: { importWallet: true },
      });
      return;
    }

    if (localStorage.getItem("dexiPassword"))
      history.push({
        pathname: "/createpassword",
        state: { importWallet: true, passwordExists: true },
      });
    else
      history.push({
        pathname: "/createpassword",
        state: { importWallet: true },
      });
  };

  const options = [
    {
      id: 1,
      buttonLabel: "Create",
      subtitle: "Create new wallet",
    },
    {
      id: 2,
      buttonLabel: "Access existing",
      subtitle: "Import your existing wallet with seed phrase",
    },
  ];

  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={[
        "d-flex",
        "flex-column",
        "justify-content-center",
        "align-items-center",
      ]}
    >
      {options?.map(({ id, buttonLabel, subtitle }) => (
        <div
          key={id}
          className={[
            "d-flex",
            "flex-column",
            "align-items-center",
            id !== 2 && "mb-5",
          ].join(" ")}
          onClick={() => {
            if (id == 1) {
              onCreateAccount();
            } else {
              onImportWallet();
            }
          }}
        >
          <div>
            <img src={Wallet} />
          </div>
          <div className="my-4">
            <BorderButton fontSize={14} width="13rem">
              {buttonLabel}
            </BorderButton>
          </div>
          <div>
            <p className={classes["subtitle-text"]}>{subtitle}</p>
          </div>
        </div>
      ))}
    </Layout>
  );
};

export default Index;
