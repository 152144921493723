import React from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/Disclaimer.svg";

import { AuthLogoHeader, BorderButton, Layout } from "../../Components";
import { useHistory, useLocation } from "react-router-dom";
const Index = () => {
  const history = useHistory();
  const location = useLocation();
  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "justify-content-center"]}
    >
      <>
        <AuthLogoHeader />
        <div
          className={[classes["disclaimer-box"], "px-4", "py-2", "mb-0"].join(
            " "
          )}
        >
          <div
            className={[
              classes["disclaimer-content-wrapper"],
              "mt-2",
              "mb-2",
              "px-3",
            ].join(" ")}
          >
            <p className={[classes["disclaimer-text"], "mb-0"].join(" ")}>
              Dexioprotocol is not responsible for the safety of your device.
              Beware of malware and NEVER share your seedphrase. By using this
              application you are agreeing to the terms and conditions of use
              which can be found by following this link
            </p>
            <p className={[classes["disclaimer-url-text"], "mb-0"].join(" ")}>
              <a
                href="https://www.dexioprotocol.com/dexi-user-agreement"
                target="_blank"
              >
                https://dexioprotocol.com/dexi-wallet/terms-and-conditions
              </a>
            </p>
            <div className="d-flex justify-content-center mt-5">
              <BorderButton
                fontSize={14}
                marginRight={5}
                minWidth="127px"
                onClick={() => {
                  history.push("/");
                }}
              >
                Discontinue
              </BorderButton>
              <BorderButton
                fontSize={14}
                minWidth="127px"
                onClick={() => {
                  localStorage.setItem("disclaimerAccepted", "true");
                  history.push({
                    pathname: "/createpassword",
                    state: location.state,
                  });
                }}
              >
                I agree
              </BorderButton>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Index;
