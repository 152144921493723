import React, { useState, useEffect } from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/TermsPolicies.svg";
import Down from "../../assets/icons/Down.svg";
import ScanIcon from "../../assets/icons/ScanIcon.svg";

import { useSelector } from "react-redux";
import {
  ChainId,
  WEB3_PROVIDERS,
  getEstimateFee,
  getEstimateTokenFee,
  getReceiveAddress,
} from "dexio-sdk";
import { useHistory, useLocation } from "react-router-dom";
import { setWallets } from "../../store/actions";
import { useDispatch } from "react-redux";
import { BorderButton, Header, Layout } from "../../Components";
import queryString from "query-string";

const Index = () => {
  const [tokenDropdownVisible, setTokenDropdownVisible] = useState(false);

  const balances = useSelector((state) => state.balances.balances);
  const wallet = useSelector((state) => state.wallet.activeWallet);
  const walletList = useSelector((state) => state.wallet.wallet);
  const location = useLocation();

  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const [fromAddress, setFromAddress] = useState("")
  const [estimatedGas, setEstimatedGas] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [loading, setLoading] = useState(false);
  const [selectedToken, setSelectedToken] = useState(balances[0]);
  const web3 = WEB3_PROVIDERS[ChainId.SMART_CHAIN];
  const history = useHistory();
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);

  useEffect(() => {
    if (walletList.length == 0) {
      let wallets = localStorage.getItem("dexiWallet");
      if (wallets) {
        wallets = JSON.parse(wallets);
        dispatch(setWallets(wallets));
        if (query.amount) {
          setAmount(query.amount);
        }
      } else {
        history.push("/");
      }
    }
  }, []);

  useEffect(() => {
    if (query.token) {
      let foundToken = balances.find(
        (item) => item.symbol.toLowerCase() == query.token.toLowerCase()
      );
      setSelectedToken(foundToken);
    }
  }, [balances]);

  useEffect(() => {
    async function fetchData() {
      console.log("wallet, selectedToken: ", wallet, selectedToken)
      const receiveAddress = await getReceiveAddress(wallet, selectedToken)
      console.log("receiveAddress : ", receiveAddress)
      setFromAddress(receiveAddress)
    }
    if (selectedToken) {
      fetchData()
    }
  }, [selectedToken])
  const onSend = async () => {
    let tempAmount = parseFloat(amount);

    if (!tempAmount) {
      alert("Please enter a valid amount");
    } else if (!address) {
      alert("Please enter an address");
    } else if (!selectedToken) {
      alert("Please select the token to send");
    } else {
      const tempSelected = balances.find(
        (item) => item.symbol == selectedToken.symbol
      );

      try {
        if (tempSelected)
          if (
            tempSelected.id !== "bitcoin" &&
            tempSelected.id !== "terra-luna" &&
            tempSelected.id !== "polkadot" &&
            tempSelected.id !== "cosmos" &&
            tempSelected.id !== "tron" &&
            tempSelected.id !== "harmony" &&
            tempSelected.id !== "solana" &&
            tempSelected.id !== "xrp" &&
            tempSelected.id !== "binancecoin2"
          )
            web3.utils.toChecksumAddress(address);
      } catch (e) {
        console.log(e);
        alert("Please enter a valid address");
        return;
      }

      // if (
      //   tempAmount &&
      //   tempSelected &&
      //   tempAmount > parseFloat(tempSelected.balance)
      // ) {
      //   alert("Please enter an amount lower than the total");

      //   return;
      // }

      if (tempSelected) {
        setLoading(true);
        const gas = await getEstimateFee(
          tempSelected?.platform ? tempSelected?.platform : "",
          fromAddress,
          `${tempAmount}`,
          address,
          wallet.seed,
          tempSelected.token_address,
          tempSelected.id,
          tempSelected?.type
        );
        console.log("gas: ", gas);

        setLoading(false);

        history.push({
          pathname: "transfer",
          state: {
            coin: tempSelected,
            fee: gas,
            amount: tempAmount,
            fromAddress: fromAddress,
            toAddress: address,
          },
        });
      }
    }
    // }
  };

  const setMaxValue = async(tempSelected) => {
    console.log("tempSelected: ", tempSelected)
    const gas = await getEstimateTokenFee(
      tempSelected?.platform,
      wallet.address,
      `${tempSelected.balance}`,
      address,
      wallet.seed,
      tempSelected?.token_address,
      tempSelected?.id,
      tempSelected?.type
    )
    setAmount(tempSelected.balance - gas)
  }

  const tempSelected = balances.find(
    (item) => item.symbol === selectedToken?.symbol
  );

  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "align-items-center"]}
    >
      <>
        <Header title="Send" />
        <div className={[classes["send-box"], "py-3", "mt-5"].join(" ")}>
          <div
            className={[
              classes["send-box-content-wrapper"],
              "d-flex",
              "flex-column",
              "align-items-center",
            ].join(" ")}
          >
            <div
              className={[
                classes["select-token-wrapper"],
                "d-flex",
                "flex-column",
                "align-items-center",
              ].join(" ")}
            >
              <div>
                <p className={[classes["select-token-text"], "mb-0"].join(" ")}>
                  Select Token
                </p>
              </div>
              <div
                className={[
                  classes["select-token-dropdown"],
                  "d-flex",
                  "align-items-center",
                ].join(" ")}
                onClick={() => setTokenDropdownVisible((ps) => !ps)}
              >
                <div className="d-flex align-items-center" style={{ flex: 1 }}>
                  <div>
                    <img
                      style={{ width: "25px", height: "25px" }}
                      alt="DEXI"
                      src={selectedToken?.logo}
                    />
                  </div>
                  <div>
                    <p
                      className={[
                        classes["dropdown-selected-text"],
                        "mb-0",
                      ].join(" ")}
                    >
                      {selectedToken?.name}
                    </p>
                  </div>
                </div>
                <div>
                  <img style={{ maxWidth: "80%" }} alt="Down" src={Down} />
                </div>
              </div>
              {tokenDropdownVisible && (
                <div
                  className={classes["select-token-dropdown-options-wrapper"]}
                >
                  {balances?.map((item) => (
                    <div
                      key={item?.id}
                      className={[
                        classes["select-token-dropdown"],
                        "d-flex",
                        "align-items-center",
                        "mb-2",
                      ].join(" ")}
                      onClick={() => {
                        setSelectedToken(item);
                        setTokenDropdownVisible((ps) => !ps);
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ flex: 1 }}
                      >
                        <div>
                          <img
                            style={{ width: "25px", height: "25px" }}
                            src={item?.logo}
                          />
                        </div>
                        <div>
                          <p
                            className={[
                              classes["dropdown-selected-text"],
                              "mb-0",
                            ].join(" ")}
                          >
                            {item?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              className={[
                classes["address-wrapper"],
                "d-flex",
                "flex-column",
                "align-items-center",
                "my-2",
              ].join(" ")}
            >
              <div>
                <p
                  className={[classes["address-title-text"], "mb-0"].join(" ")}
                >
                  Address
                </p>
              </div>
              <div
                className={[
                  classes["select-token-dropdown"],
                  "d-flex",
                  "align-items-center",
                ].join(" ")}
              >
                <div className="d-flex align-items-center" style={{ flex: 1 }}>
                  <div
                    className={[classes["input-wrapper"], "d-flex"].join(" ")}
                  >
                    <input
                      className="mb-0"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  className={[
                    classes["field-scan-icon-wrapper"],
                    "d-flex",
                    "flex-column",
                    "align-items-center",
                    "justify-content-center",
                  ].join(" ")}
                  style={{ opacity: 0 }}
                >
                  <img style={{ maxWidth: "80%" }} alt="Down" src={ScanIcon} />
                </div>
              </div>
            </div>
            <div
              className={[
                classes["max-value-main-wrapper"],
                "d-flex",
                "flex-column",
                "align-items-center",
              ].join(" ")}
            >
              <div>
                <p className={[classes["amount-title-text"], "mb-0"].join(" ")}>
                  Amount
                </p>
              </div>
              <div
                className={[
                  classes["max-value-wrapper"],
                  "d-flex",
                  "align-items-center",
                ].join(" ")}
              >
                <div className="d-flex align-items-center" style={{ flex: 1 }}>
                  <div
                    className={[classes["input-wrapper"], "d-flex"].join(" ")}
                  >
                    <input
                      className="mb-0"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  className={[
                    classes["max-text-wrapper"],
                    "d-flex",
                    "flex-column",
                    "align-items-center",
                    "justify-content-center",
                  ].join(" ")}
                  onClick={() => {
                    if (tempSelected) setMaxValue(tempSelected);
                  }}
                >
                  <p className={[classes["max-text"], "mb-0"].join(" ")}>MAX</p>
                </div>
              </div>
            </div>
            <div
              className={[
                classes["address-wrapper"],
                "d-flex",
                "flex-column",
                "align-items-center",
                "my-2",
              ].join(" ")}
            >
              <div>
                <p
                  className={[classes["address-title-text"], "mb-0"].join(" ")}
                >
                  Max value: {selectedToken?.balance}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-4 mt-4">
          <BorderButton
            fontSize={14}
            width="13rem"
            onClick={() => {
              onSend();
            }}
          >
            Continue
          </BorderButton>
        </div>
      </>
      {/* <div className="pb-4" style={{ position: "absolute", bottom: 0 }}>
        <BorderButton fontSize={14} width="13rem">
          Continue
        </BorderButton>
      </div> */}
      <div
        className="pb-4 d-flex justify-content-around mt-5"
        style={{ minWidth: "100%" }}
      >
        {/* <BorderButton fontSize={14} width="9rem">
          Wallet
        </BorderButton> */}
        {/* <button className={classes["wallet-button"]}>Wallet</button>
        <BorderButton fontSize={14} width="9rem">
          Settings
        </BorderButton> */}
      </div>
    </Layout>
  );
};

export default Index;
