import * as actionTypes from "../actionTypes";
import { hunterTokenList } from "dexio-sdk";
let tempTemp = hunterTokenList.map((item) => {
  if (item.id == "binancecoin") return { ...item, type: "bep20" };
  else return item;
});

const initialState: BalanceState = {
  balances: [],
  nftBalances: [],
  tokens: tempTemp,
  transactions: [],
  activeNft: null,
};

const reducer = (
  state: BalanceState = initialState,
  action: any
): BalanceState => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_NFT:
      const activeNft: any[] = action.selectedNft;
      return {
        ...state,
        activeNft,
      };
    case actionTypes.SET_TRANSACTIONS:
      const newTransactions: any[] = action.transactions;
      return {
        ...state,
        transactions: newTransactions,
      };

    case actionTypes.SET_BALANCES:
      const newBalances: Balance[] = action.balance;
      return {
        ...state,
        balances: newBalances,
      };

    case actionTypes.SET_NFT_BALANCES:
      const newNftBalances: NftToken[] = action.nfts;

      return {
        ...state,
        nftBalances: newNftBalances,
      };

    case actionTypes.SET_TOKENS:
      const newTokens: Token[] = action.tokens;
      return {
        ...state,
        tokens: newTokens,
      };

    case actionTypes.ADD_TOKEN:
      const newToken: Token = action.token;
      localStorage.setItem(
        `tokens${action.wallet}`,
        JSON.stringify([...state.tokens, newToken])
      );

      return {
        ...state,
        tokens: [...state.tokens, newToken],
      };
  }
  return state;
};

export default reducer;
