import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import WalletReducer from "./reducers/wallet";
import BalanceReducer from "./reducers/balance";
import TerraWalletReducer from "./reducers/terra-wallet";

const store = createStore<IRootState, any, any, any>(
  combineReducers({
    wallet: WalletReducer,
    balances: BalanceReducer,
    terra_wallet: TerraWalletReducer,
  }),
  applyMiddleware(thunk, logger)
);
export default store;
