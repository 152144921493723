import React, { useState, useEffect } from "react";
import modalStyle from "./modalStyle.module.css";
import { encrypt, decrypt} from "dexio-sdk";
import {message} from "antd"
import Copy from "../../assets/icons/Copy.svg";



export default function SecretPhrase(props) {
  const [phrase, setPhrase] = useState("");
  useEffect(() => {
    setPhrase(decrypt(props.selectedWallet.seed));
  }, []);
  return (
    <div className={modalStyle.walletModal}>
      <div>
        <div>
          <p className="mb-2">Your secret phrase</p>
          <div className="d-flex flex-wrap justify-content-between">
            {phrase.split(" ").map((item, index) => (
              <div>
                <p>{index + 1}</p>
                <input type="text" value={item} readOnly />
              </div>
            ))}
          </div>
          <p
            className="my-3"
            onClick={() => {
              message.success("Address Copied to Clipboard");
              navigator.clipboard.writeText(decrypt(props.selectedWallet.seed));
            }}
          >
            Copy To Clipboard{" "}
            <img src={Copy} style={{ marginLeft: "18px" }} alt="" />
          </p>
        </div>
      </div>
      <div className="text-center">
        <img src="/images/Dexioprotocol Wallet.png" alt="" />
      </div>
    </div>
  );
}
