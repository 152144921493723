import React, { useState, useEffect } from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/TermsPolicies.svg";
import Down from "../../assets/icons/Down.svg";
import Copy from "../../assets/icons/Copy.svg";
import { useSelector } from "react-redux";
import { message } from "antd";
import { CgTokenList, hunterTokenList, getReceiveAddress } from "dexio-sdk";
import { Header, Layout } from "../../Components";

const Index = () => {
  const [tokenDropdownVisible, setTokenDropdownVisible] = useState(false);
  const wallet = useSelector((state) => state.wallet.activeWallet);

  const [selectedToken, setSelectedToken] = useState(CgTokenList[0]);
  const [initAddress, setInitAddress] = useState(wallet.address);
  const [search, setAddress] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("wallet : ", wallet)
        console.log("selectedToken : ", selectedToken)
        const newTokensArr = await getReceiveAddress(wallet, selectedToken);
        console.log("newTokensArr : ", newTokensArr)
        setInitAddress(newTokensArr);
      } catch (error) {
        console.log(error);
      }
    };

    if (selectedToken) fetchData();
  }, [selectedToken]);

  let newTokenList = hunterTokenList;
  if (search) {
    newTokenList = hunterTokenList.filter(
      (item) =>
        item?.name.toLowerCase().includes(search.toLowerCase()) ||
        item?.symbol.toLowerCase().includes(search.toLowerCase()) ||
        item?.token_address.toLowerCase().includes(search.toLowerCase())
    );
  }
  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "justify-content-between"]}
    >
      <div>
        <Header title="Receive" />
        <div className={[classes["send-box"], "py-3", "mt-5"].join(" ")}>
          <div
            className={[
              classes["send-box-content-wrapper"],
              "d-flex",
              "flex-column",
              "align-items-center",
            ].join(" ")}
          >
            <div
              className={[
                classes["select-token-wrapper"],
                "d-flex",
                "flex-column",
                "align-items-center",
              ].join(" ")}
            >
              <div>
                <p className={[classes["select-token-text"], "mb-0"].join(" ")}>
                  Select Token
                </p>
              </div>
              <div
                className={[
                  classes["select-token-dropdown"],
                  "d-flex",
                  "align-items-center",
                ].join(" ")}
                onClick={() => setTokenDropdownVisible((ps) => !ps)}
              >
                <div className="d-flex align-items-center" style={{ flex: 1 }}>
                  <div>
                    <img
                      style={{ width: "32px", height: "32px" }}
                      alt="DEXI"
                      src={selectedToken?.logo}
                    />
                  </div>
                  <div>
                    <p
                      className={[
                        classes["dropdown-selected-text"],
                        "mb-0",
                      ].join(" ")}
                    >
                      {selectedToken?.symbol.toUpperCase()}
                    </p>
                  </div>
                </div>
                <div>
                  <img style={{ maxWidth: "80%" }} alt="Down" src={Down} />
                </div>
              </div>
              {tokenDropdownVisible && (
                <div
                  className={classes["select-token-dropdown-options-wrapper"]}
                >
                  <div
                    className={[
                      classes["select-token-dropdown"],
                      "d-flex",
                      "align-items-center",
                      "mb-2",
                    ].join(" ")}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ flex: 1 }}
                    >
                      <input
                        type="text"
                        placeholder="Search By Address or Name"
                        value={search}
                        onChange={(e) => setAddress(e.target.value)}
                        style={{
                          background: "transparent",
                          border: "none",
                          outline: "none",
                          width: "100%",
                          padding: "0 10px",
                          color: "white",
                        }}
                      />
                    </div>
                  </div>
                  {newTokenList?.map((token) => (
                    <div
                      className={[
                        classes["select-token-dropdown"],
                        "d-flex",
                        "align-items-center",
                        "mb-2",
                      ].join(" ")}
                      onClick={() => {
                        setSelectedToken(token);
                        setTokenDropdownVisible((ps) => !ps);
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ flex: 1 }}
                      >
                        <div>
                          <img
                            style={{ width: "32px", height: "32px" }}
                            src={token?.logo}
                          />
                        </div>
                        <div className="ml-3">
                          <p
                            className={[
                              classes["dropdown-selected-text"],
                              "mb-0",
                            ].join(" ")}
                          >
                            {token.symbol.toUpperCase()}
                            <sup>
                              {token.type && token.type !== "null"
                                ? token.type
                                : ""}
                            </sup>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <p
              className={[classes["address"]].join(" ")}
              style={{ marginRight: "70px" }}
            >
              {initAddress}
              <img
                src={Copy}
                onClick={() => {
                  message.success("Address Copied to Clipboard");
                  navigator.clipboard.writeText(initAddress);
                }}
                style={{ marginLeft: "18px" }}
                alt=""
              />
            </p>
          </div>
        </div>
      </div>
      <div
        className="pb-4 d-flex justify-content-around mt-5"
        style={{ minWidth: "100%" }}
      ></div>
    </Layout>
  );
};

export default Index;
