import * as actionTypes from "../actionTypes";

const initialState: TerraWalletState = {
  wallet: [],
  activeWallet: { address: "", mnemonic: "", privateKey: [], balances: [] },
};

const terra_wallet = (
  state: TerraWalletState = initialState,
  action: any
): TerraWalletState => {
  switch (action.type) {
    case actionTypes.SET_TERRA_WALLET:
      const newWallet: TerraWallet = action.wallet;
      return {
        ...state,
        wallet: [...state.wallet, newWallet],
        activeWallet: newWallet,
      };
    case actionTypes.SET_ACTIVE_TERRA_WALLET:
      const activeWallet: TerraWallet = action.wallet;
      return {
        ...state,
        activeWallet: activeWallet,
      };
  }

  return state;
};

export default terra_wallet;
