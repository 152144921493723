import React, { useEffect, useState } from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/SecretPhrase.svg";
import { encrypt, decrypt } from "dexio-sdk";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addWallet } from "store/actions";
import { AuthLogoHeader, BorderButton, Layout } from "../../Components";
import Back from "../../assets/icons/Back.svg";
import { Modal, message } from "antd";
const ethers = require("ethers");
const { utils } = require("ethers");
function hasWhiteSpace(s) {
  return s.indexOf(" ") >= 0;
}
const Index = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const [phrase, setPhrase] = useState([]);
  const [importWallet, setImportWallet] = useState(false);
  const [phraseWord, setPhraseWord] = useState("");
  const history = useHistory();

  const onCreatWallet = () => {
    const randomMnemonic = ethers.Wallet.createRandom().mnemonic;
    const wallet = ethers.Wallet.fromMnemonic(randomMnemonic.phrase);
    let encPhrase = encrypt(randomMnemonic.phrase);
    let encKey = encrypt(wallet.privateKey);
    let existWallets = localStorage.getItem("dexiWallet");
    if (existWallets) {
      localStorage.setItem(
        "dexiWallet",
        JSON.stringify([
          ...JSON.parse(existWallets),
          {
            ...wallet,
            privateKey: encKey,
            seed: encPhrase,
            name: `Account Dexi ${JSON.parse(existWallets).length + 1}`,
          },
        ])
      );
      dispatch(
        addWallet({
          ...wallet,
          privateKey: encKey,
          seed: encPhrase,
          name: `Account Dexi ${JSON.parse(existWallets).length + 1}`,
        })
      );
    } else {
      localStorage.setItem(
        "dexiWallet",
        JSON.stringify([
          {
            ...wallet,
            privateKey: encKey,
            seed: encPhrase,
            name: `Account Dexi 1`,
          },
        ])
      );
      dispatch(
        addWallet({
          ...wallet,
          privateKey: encKey,
          seed: encPhrase,
          name: `Account Dexi 1`,
        })
      );
    }
    setPhrase(randomMnemonic.phrase.split(" "));
  };

  const onLoadWallet = () => {
    let wallet = null;
    var re = /[0-9A-Fa-f]{6}/g;
    var inputString = phraseWord;
    if (!hasWhiteSpace(phraseWord)) {
      Modal.error({
        title: "Invalid Phrase",
        content: "Please enter the phrase with spaces separated",
      });
      return;
    }
    if (re.test(inputString)) {
      let tempWallet = new ethers.Wallet(phraseWord);
      wallet = {
        address: tempWallet.address,
        privateKey: phraseWord,
        seed: "",
      };
      message.success("Please wait while we load your wallet");
    } else {
      wallet = ethers.Wallet.fromMnemonic(phraseWord);
      message.success("Please wait while we load your wallet");
    }

    if (wallet) {
      let encPhrase = encrypt(phraseWord);
      let encKey = encrypt(wallet.privateKey);
      let existWallets = localStorage.getItem("dexiWallet");
      if (existWallets) {
        localStorage.setItem(
          "dexiWallet",
          JSON.stringify([
            ...JSON.parse(existWallets),
            {
              ...wallet,
              privateKey: encKey,
              seed: encPhrase,
              name: `Account Dexi ${JSON.parse(existWallets).length + 1}`,
            },
          ])
        );
        dispatch(
          addWallet({
            ...wallet,
            privateKey: encKey,
            seed: encPhrase,
            name: `Account Dexi ${JSON.parse(existWallets).length + 1}`,
          })
        );
      } else {
        localStorage.setItem(
          "dexiWallet",
          JSON.stringify([
            {
              ...wallet,
              privateKey: encKey,
              seed: encPhrase,
              name: `Account Dexi 1`,
            },
          ])
        );
        dispatch(
          addWallet({
            ...wallet,
            privateKey: encKey,
            seed: encPhrase,
            name: `Account Dexi 1`,
          })
        );
      }

      history.push("/main");
    } else {
      alert("Invalid seedphrase");
    }
  };

  useEffect(() => {
    let password = localStorage.getItem("dexiPassword");
    if (password) {
      const importWallet = location?.state?.importWallet;
      if (importWallet) {
        setImportWallet(true);
      } else {
        onCreatWallet();
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={[
        "d-flex",
        "flex-column",
        // "justify-content-between",
        "justify-content-center",
        "align-items-center",
      ]}
    >
      <>
        <div
          onClick={() => {
            history.goBack();
          }}
          style={{
            position: "absolute",
            left: "10px",
            top: "20px",
            zIndex: "999",
          }}
        >
          <img src={Back} alt="Back" />
        </div>
        <AuthLogoHeader />
        <div className={[classes["phrase-box-main"], "py-3"].join(" ")}>
          <div
            className={[classes["policies-content-wrapper"], "px-3"].join(" ")}
          >
            <div className={classes["phrase-title-wrapper"]}>
              <p className="mb-0">You Secret Backup Phrase</p>
            </div>
            <div
              className={[
                classes["phrase-content-wrapper"],
                "d-flex",
                "flex-column",
                " align-items-center",
              ].join(" ")}
            >
              <div className={classes["phrase-para-wrapper"]}>
                {importWallet ? (
                  <p>"Please enter your secret phrase or private key"</p>
                ) : (
                  ``
                )}
                <p>
                  Please, make sure you have carefully written down your backup
                  phrase somewhere safe. You will need this phrase to use and
                  restore your wallet.
                </p>
              </div>
              <div
                className={[
                  classes["phrase-boxes-wrapper"],
                  "d-flex",
                  "flex-wrap",
                  "justify-content-between",
                  "align-items-center",
                ].join(" ")}
              >
                {importWallet ? (
                  <>
                    <textarea
                      className={classes["phrase-box"]}
                      type="text"
                      value={phraseWord}
                      onChange={(e) => {
                        setPhraseWord(e.target.value);
                      }}
                      rows="3"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                        height: "75px",
                        textAlign: "left",
                        lineHeight: "20px",
                        paddingTop: "6px",

                        padding: "6px 30px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    {phrase.map((i, j) => (
                      <div className={classes["phrase-box"]} key={j}>
                        {i}
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className={classes["warning-text-wrapper"]}>
                <p className="mb-0">
                  WARNING: Never reveal your seedphrase to anyone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>

      {importWallet ? (
        <div className="my-5">
          <BorderButton
            fontSize={14}
            minWidth="80%"
            onClick={() => onLoadWallet()}
          >
            Import my wallet
          </BorderButton>
        </div>
      ) : (
        <div className="my-5">
          <BorderButton
            fontSize={14}
            minWidth="80%"
            onClick={() => history.push("/main")}
          >
            Import my wallet
          </BorderButton>
        </div>
      )}
      <div
        className="pb-4 d-flex justify-content-around"
        style={{ minWidth: "100%" }}
      ></div>
    </Layout>
  );
};

export default Index;
