import React from "react";
import classes from "./style.module.css";
const index = ({
  children,
  type,
  wrapperWidth,
  marginRight,
  width,
  value,
  onChange,
  ...props
}) => {
  return (
    <div style={{ width: wrapperWidth }}>
      <input
        style={{ width }}
        type={type}
        className={classes["neon-input"]}
        autoFocus
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default index;
