import React from "react";
import classes from "./style.module.css";

const index = ({ Image, title, onClick }) => {
  return (
    <div
      className={[
        classes["option"],
        "d-flex",
        "align-items-center",
        `${Image ? "justify-content-between" : "justify-content-center"}`,
      ].join(" ")}
      onClick={onClick}
    >
      <p>{title}</p>
      {Image ? <img src={Image} alt="Image" /> : ""}
    </div>
  );
};

export default index;
