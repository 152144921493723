import React from "react";
import classes from "./style.module.css";
const index = ({
  children,
  fontSize,
  lightBack,
  marginRight,
  width,
  minWidth,
  onClick,
  ...props
}) => {
  return (
    <button
      style={{ width, minWidth, marginRight, fontSize }}
      onClick={onClick}
      className={[
        classes["border-button"],
        "px-3",
        lightBack && classes["light-back"],
      ].join(" ")}
    >
      {children}
    </button>
  );
};

export default index;
