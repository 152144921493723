import React from "react";
import classes from "./style.module.css";
import ScanIcon from "../../assets/icons/ScanIcon.svg";
import Back from "../../assets/icons/Back.svg";
import { useHistory } from "react-router-dom";
const Index = ({ title, varriant }) => {
  const history = useHistory();
  return (
    <div
      className={[
        classes["header-wrapper"],
        "d-flex",
        "justify-content-between",
        "align-items-center",
      ].join(" ")}
    >
      <div
        onClick={() => {
          history.goBack();
        }}
      >
        <img src={Back} alt="Back" />
      </div>
      <div>
        <p className={[classes["header-title"], "mb-0"].join(" ")}>{title}</p>
      </div>
      {!varriant && (
        <div style={{ opacity: 0 }}>
          <img src={ScanIcon} alt="Scan" />
        </div>
      )}
      {varriant && varriant === "screen" && (
        <div>
          <img src={Back} alt="Back" />
        </div>
      )}
    </div>
  );
};

export default Index;
