import React from "react";
import classes from "./style.module.css";
const index = ({ backgroundImage, restClasses, children }) => {
  return (
    <div
      style={{
        backgroundImage: `url("${backgroundImage}")`,
      }}
      className={[classes["screen-wrapper"], ...restClasses].join(" ")}
    >
      {children}
    </div>
  );
};

export default index;
