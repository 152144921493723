import React, { useState } from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/AddAssets.svg";
import { useLocation, useHistory } from "react-router-dom";
import { BorderButton, Header, Layout } from "../../Components";
import { LoadingOutlined } from "@ant-design/icons";
import { transfer } from "dexio-sdk";
import { useSelector } from "react-redux";

const Index = () => {
  const location = useLocation();
  const { coin, fee, amount, fromAddress, toAddress } = location?.state;
  const [loading, setLoading] = useState();
  const wallet = useSelector((state) => state.wallet.activeWallet);
  const history = useHistory();
  const onSubmit = async () => {
    setLoading(true);
    const tx = await transfer(
      coin?.platform,
      coin.token_address,
      wallet?.seed,
      `${amount}`,
      toAddress,
      coin?.id,
      coin?.type
    );
    setLoading(false);

    if (tx) {
      alert("Transfer made successfully");
      history.push("main");
    } else alert("Unable to complete Transfer.");
  };

  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "align-items-center"]}
    >
      <>
        <Header title="Transfer" />
        <div className={[classes["transfer-box"], "py-3", "mt-5"].join(" ")}>
          <div
            className={["d-flex", "flex-column", "align-items-center"].join(
              " "
            )}
          >
            <div className={classes["transfer-content-wrapper"]}>
              <div
                className={[
                  classes["transfer-dexi-wrapper"],
                  "d-flex",
                  "justify-content-center",
                ].join(" ")}
              >
                <p className="mb-0">{`-${amount} ${coin.symbol.toUpperCase()}`}</p>
              </div>
            </div>
            <div className={[classes["asset-box"], "my-3"].join(" ")}>
              <div
                className={[
                  classes["asset-first-row"],
                  "d-flex",
                  "justify-content-between",
                ].join(" ")}
              >
                <p className="mb-0">Assets</p>
                <p className="mb-0">{coin.symbol.toUpperCase()}</p>
              </div>
              <div
                className={[
                  classes["asset-sec-row"],
                  "d-flex",
                  "align-items-center",
                  "my-3",
                ].join(" ")}
              >
                <p className={[classes["to-from-text"], "mb-0"].join(" ")}>
                  From
                </p>
                <p
                  className={["mb-0", classes["wallet-address-text"]].join(" ")}
                >
                  {fromAddress}
                </p>
              </div>
              <div
                className={[
                  classes["asset-thrd-row"],
                  "d-flex",
                  "align-items-center",
                ].join(" ")}
              >
                <p className={[classes["to-from-text"], "mb-0"].join(" ")}>
                  To
                </p>
                <p
                  className={["mb-0", classes["wallet-address-text"]].join(" ")}
                >
                  {toAddress}
                </p>
              </div>
            </div>
            <div className={classes["networkfee-box"]}>
              <div
                className={[
                  classes["networkfee-first-row"],
                  "d-flex",
                  "justify-content-between",
                ].join(" ")}
              >
                <p className="mb-0">Network Fee</p>
                <p className="mb-0">${parseFloat(fee).toFixed(8)} </p>
              </div>
              {/* <div
                className={[
                  classes["networkfee-sec-row"],
                  "d-flex",
                  "justify-content-between",
                  "mt-3",
                ].join(" ")}
              >
                <p className="mb-0">Max Total</p>
                <p className="mb-0">$ 0,5$</p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="pb-4 mt-4">
          <BorderButton
            fontSize={14}
            width="13rem"
            onClick={() => {
              onSubmit();
            }}
          >
            {loading ? <LoadingOutlined /> : "Continue"}
          </BorderButton>
        </div>
      </>

      <div
        className="pb-4 d-flex justify-content-around mt-5"
        style={{ minWidth: "100%" }}
      >
        {/* <button className={classes["wallet-button"]}>Wallet</button>
        <BorderButton fontSize={14} width="9rem">
          Settings
        </BorderButton> */}
      </div>
    </Layout>
  );
};

export default Index;
