export const SET_WALLET = "sets a wallet";
export const SET_TERRA_WALLET = "sets a terra wallet";
export const REMOVE_WALLET = "removes a wallet";
export const UPDATE_WALLETS = "update a wallet";
export const SET_BALANCES = "sets balances";
export const SET_TOKENS = "SET_TOKENS";
export const ADD_TOKEN = "ADD_TOKEN";
export const SET_INITIAL_WALLETS = "SET_INITIAL_WALLETS";
export const SET_ACTIVE_WALLET = "SET_ACTIVE_WALLET";
export const SET_ACTIVE_TERRA_WALLET = "SET_ACTIVE_TERRA_WALLET";
export const SET_NFT_BALANCES = "SET_NFT_BALANCES";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const SET_ACTIVE_NFT = "SET_ACTIVE_NFT";
