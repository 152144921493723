import React, { useState } from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/Account.svg";
import Down from "../../assets/icons/Down.svg";
import { useHistory } from "react-router-dom";
import {
  BorderButton,
  Header,
  InternalHeader,
  Layout,
  BorderCard,
} from "../../Components";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";

const Index = () => {
  let transactions = useSelector((state) => state.balances.transactions);

  transactions = transactions.map((item) => {
    return {
      ...item,
      created: moment(new Date(item.timeStamp * 1000).toUTCString()).format(
        "DD-MM-YYYY"
      ),
    };
  });
  let groupedTransactions = _.groupBy(transactions, "created");

  const history = useHistory();
  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "align-items-center"]}
    >
      <>
        <Header title="Transaction" varriant={false} />
        <div className={[classes["asset-list-box"], "py-3", "mt-3"].join(" ")}>
          <div className={classes["asset-title-wrapper"]}>
            <p className="mb-0">History</p>
          </div>
          <div className="mt-4 mb-2" style={{ minWidth: "90%" }}>
            {Object.keys(groupedTransactions).map((item) => {
              return (
                <>
                  <div className={classes["phrase-para-date"]}>
                    <p>{item}</p>
                  </div>
                  {groupedTransactions[item].map(({ from, hash, type }) => (
                    <BorderCard
                      onClick={() => {
                        if (type == "bsc")
                          window.open(
                            `https://bscscan.com/tx/${hash}`,
                            "_blank"
                          );
                        else
                          window.open(
                            `https://etherscan.io/tx/${hash}`,
                            "_blank"
                          );
                      }}
                    >
                      <p style={{ textAlign: "center", color: "red" }}>
                        {hash.substr(0, 20)}...
                      </p>
                      <div className={classes["phrase-para-id"]}>
                        <p> From:</p>
                        <p>{from.substr(0, 15)}...</p>
                      </div>
                    </BorderCard>
                  ))}
                </>
              );
            })}

            <hr
              className="mt-4"
              style={{
                color: "#00b0f4",
                justifyContent: "center",
                margin: "0 auto",
                border: "1px solid #00b0f4",
                width: "80%",
              }}
            />
          </div>
        </div>
      </>
      <div
        className="pb-4 d-flex justify-content-around mt-5"
        style={{ minWidth: "100%" }}
      >
        <BorderButton
          fontSize={14}
          width="9rem"
          onClick={() => {
            history.push("/main");
          }}
        >
          Wallet
        </BorderButton>
        <button
          className={classes["wallet-button"]}
          onClick={() => {
            history.push("/settings");
          }}
        >
          Settings
        </button>
      </div>
    </Layout>
  );
};

export default Index;
