import { setActiveNft } from "store/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
const NftCardItem = ({ data }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const metadata = JSON.parse(data.metadata)

  if (!metadata) return null;

  return (
    <div className="col-4 col-sm-4 mb-3 mb-sm-0 p-1">
      <div style={{ cursor: "pointer" }}>
        {metadata.image ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100px" }}
            onClick={() => {
              dispatch(setActiveNft(data));
              history.push("/nft-detail");
            }}
          >
            <img
              src={metadata.image}
              alt=""
              className="img-fluid"
              style={{ height: "100%", borderRadius: "15px", objectFit: 'cover' }}
            />
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center text-center"
            style={{
              width: "100%",
              height: "100px",
              background: "grey",
            }}
          >
            Image not available
          </div>
        )}
        <div style={{ textAlign: "center", color: "white", marginTop: 5, fontSize: "12px" }}>
          {metadata.name}
        </div>
      </div>
    </div>
  );
};

export default NftCardItem;
