import React, { useState, useEffect } from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/SecretPhrase.svg";
import Twitter from "../../assets/icons/twitterWhite.svg";
import { fetchCoinGeckoInfo } from "dexio-sdk";
import Web from "../../assets/icons/webWhite.svg";
import { useLocation, useHistory } from "react-router-dom";
import { BorderButton, Layout, InternalHeader } from "../../Components";
import moment from "moment";

const CoinGecko = require("coingecko-api");
const CoinGeckoClient = new CoinGecko();

const Time = {
  Twenty_four_hours: "24H",
  Seven_days: "7D",
  One_month: "1M",
  Six_months: "6M",
  All: "ALL",
};
function currencyFormat(num) {
  console.log(num)
  if (typeof num == Number)
    return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  else return "";
}

const formatCash = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

const Index = () => {
  const [fetchedNewChart, setFetchingNewChart] = useState(false);
  const [chartData, setChartData] = useState({});
  const [fetchTokenData, setFetchTokenData] = useState({});
  const [timePeriod, setPeriod] = useState(Time.Twenty_four_hours);
  const [priceHistory, setPriceHistory] = useState([]);
  const location = useLocation();
  const tokenData = location.state;
  const history = useHistory();

  const fetchPriceHistory = async (days) => {
    const fetchData = await fetchCoinGeckoInfo(tokenData.id);
    setFetchTokenData(fetchData);
    console.log("fetchPriceHistory : ", fetchData);
    setFetchingNewChart(false);
    let res = await CoinGeckoClient.coins.fetchMarketChart(tokenData.id, {
      days,
    });

    setPriceHistory(res.data.prices);
    setChart(res.data.prices);
  };

  useEffect(() => {
    fetchPriceHistory(1);
  }, []);

  useEffect(() => {
    if (chartData.data) setFetchingNewChart(true);
  }, [chartData]);

  const setChart = (data) => {
    let tempChart = {
      id: "priceChart",
      color: "#469F3E",
      fontSize: 14,
      textColor: "black",
      dots: {
        text: {
          fill: "#000",
        },
      },
      data: data.map((item) => {
        return {
          x: moment(item[0]).format("MM/DD/YY HH:mm:ss"),
          y: item[1],
        };
      }),
    };

    setChartData(tempChart);
  };

  useEffect(() => {
    switch (timePeriod) {
      case "7D":
        fetchPriceHistory(7);
        break;
      case "1M":
        fetchPriceHistory(30);
        break;
      case "6M":
        fetchPriceHistory(180);
        break;
      case "ALL":
        fetchPriceHistory("max");
        break;
      case "24H":
        fetchPriceHistory(1);
        break;
    }
  }, [timePeriod]);

  console.log(chartData);

  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "align-items-center"]}
    >
      <>
        <InternalHeader showBack={true} />
        <div className={[classes["transfer-box"], "py-3", "mt-5"].join(" ")}>
          <div
            className={["d-flex", "flex-column", "align-items-center"].join(
              " "
            )}
          >
            <div className={classes["transfer-content-wrapper"]}>
              <div
                className={[
                  classes["transfer-dexi-wrapper"],
                  "d-flex",
                  "align-items-start",
                  "justify-content-between",
                ].join(" ")}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={tokenData?.logo}
                    alt=""
                    className="me-3"
                    style={{ width: 30, height: 30, borderRadius: "50%" }}
                  />
                  <div>
                    <h2>
                      <span>{tokenData?.name} </span>(
                      {tokenData?.symbol.toUpperCase()})
                    </h2>
                    <h4>
                      ${tokenData?.usdPrice}{" "}
                      <span>{tokenData?.priceChange24H}%</span>
                    </h4>
                  </div>
                </div>
                <div>
                  {fetchTokenData?.twitterLink ? (
                    <img
                      src={Twitter}
                      onClick={() =>
                        window.open(fetchTokenData?.twitterLink, "_blank")
                      }
                      style={{ marginRight: "5px" }}
                      alt=""
                    />
                  ) : (
                    ""
                  )}

                  {fetchTokenData?.homePage ? (
                    <img
                      src={Web}
                      onClick={() =>
                        window.open(fetchTokenData?.homePage, "_blank")
                      }
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className={[classes["details-wrapper"]].join(" ")}>
                <p>
                  <span>Market Cap</span>{" "}
                  {fetchTokenData?.marketCap ? (
                    <span>
                      {currencyFormat(fetchTokenData?.marketCap?.usd)}
                    </span>
                  ) : (
                    "$0.0"
                  )}
                </p>
                <p>
                  <span>Circulating Supply</span>{" "}
                  <span>{`${
                    fetchTokenData
                      ? formatCash(fetchTokenData.circulatingSupply)
                      : 0
                  } ${tokenData.symbol.toUpperCase()}`}</span>
                </p>
                <p>
                  <span>Total Supply</span>{" "}
                  <span>
                    {formatCash(
                      fetchTokenData?.totalSupply
                        ? fetchTokenData?.totalSupply
                        : 0
                    )}{" "}
                    {tokenData.symbol.toUpperCase()}
                  </span>
                </p>
                <p>
                  <span>Diluted MCap</span>{" "}
                  <span>
                    {fetchTokenData
                      ? currencyFormat(fetchTokenData?.delutedMarketCap)
                      : ""}
                  </span>
                </p>
                <p className="border-0">
                  <span>Trending Volume</span>{" "}
                  <span>{`${currencyFormat(fetchTokenData?.volume24H)}`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
      <div
        className="pb-4 d-flex justify-content-around mt-5"
        style={{ minWidth: "100%" }}
      >
        <BorderButton
          fontSize={14}
          width="9rem"
          onClick={() => {
            history.push("/main");
          }}
        >
          Wallet
        </BorderButton>

        <BorderButton
          fontSize={14}
          width="9rem"
          onClick={() => {
            history.push("/settings");
          }}
        >
          Settings
        </BorderButton>
      </div>
    </Layout>
  );
};

export default Index;
