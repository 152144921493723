import * as actionTypes from "../actionTypes";

const initialState: WalletState = {
  wallet: [],
  activeWallet: { name: "", address: "", privateKey: "", seed: "" },
};

const reducer = (
  state: WalletState = initialState,
  action: any
): WalletState => {
  switch (action.type) {
    case actionTypes.SET_WALLET:
      const newWallet: Wallet = action.wallet;
      return {
        ...state,
        wallet: [...state.wallet, newWallet],
      };
    case actionTypes.SET_INITIAL_WALLETS:
      return {
        ...state,
        wallet: action.wallets,
      };
    case actionTypes.UPDATE_WALLETS:
      return {
        ...state,
        wallet: action.wallets,
      };

    case actionTypes.REMOVE_WALLET:
      return {
        ...state,
        wallet: state.wallet.filter(
          (item) => item.address !== action.payload.wallet
        ),
      };

    case actionTypes.SET_ACTIVE_WALLET:
      return {
        ...state,
        activeWallet: action.wallet,
      };
  }

  return state;
};

export default reducer;
