import React from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/settings.svg";
import Logo from "../../assets/images/DexioprotocolWallet.svg";
import { message, Modal } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveWallet, removeWallet } from "store/actions/wallet";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

import {
  AuthLogoHeader,
  BorderButton,
  Input,
  Layout,
  Header,
  Option,
} from "../../Components";
const Index = () => {
  const location = useLocation();
  const history = useHistory();
  const account = location?.state;

  const wallets = useSelector((state) => state.wallet.wallet);

  const activeWallet = useSelector((state) => state.wallet.activeWallet);

  const dispatch = useDispatch();

  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "justify-content-between"]}
    >
      <>
        <div>
          <Header title="Account Settings" />
          <div className={[classes["wrapper"], "mt-5"].join(" ")}>
            <div>
              {/* <Option title="Rename" Image="" />
              <Option title="Secret phrase" Image="" />{" "} */}
              {/* <Option
                title="Remove"
                Image=""
                onClick={() => {
                  Modal.confirm({
                    title: "Do you Want to delete this account?",
                    onOk() {
                      dispatch(
                        removeWallet(
                          account.address,
                          activeWallet.address,
                          wallets
                        )
                      );

                      message.success("Account Removed.");
                      history.push("/settings");
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                }}
              /> */}
              <img src={Logo} alt="" />
            </div>
          </div>
        </div>
        {/* <div
          className="pb-4 d-flex justify-content-around mt-5"
          style={{ minWidth: "100%" }}
        >
          <button className={classes["wallet-button"]}>Wallet</button>
          <BorderButton fontSize={14} width="9rem">
            Settings
          </BorderButton>
        </div> */}
      </>
    </Layout>
  );
};

export default Index;
