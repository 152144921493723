import React, { useState, useEffect } from "react";
import classes from "./style.module.css";
import BackgroundImage from "../../assets/backgrounds/Password.svg";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { encrypt, decrypt } from "dexio-sdk";
import { setWallets } from "../../store/actions";
import { useDispatch } from "react-redux";
import { AuthLogoHeader, BorderButton, Input, Layout } from "../../Components";

const Index = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordExists, setPasswordExists] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const history = useHistory();

  useEffect(() => {
    if (location?.state?.passwordExists) setPasswordExists(true);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (passwordExists) {
      let localPasswordRequired = localStorage.getItem("passRequired");

      console.log(localPasswordRequired == "true");
      if (localPasswordRequired && localPasswordRequired == "true")
        onCreateWallet(false);
    }
  }, [passwordExists]);

  const onCreateWallet = async (passRequired = true) => {
    if (!password && passRequired) {
      alert("Please enter a password");
      return;
    }
    if (passwordExists) {
      let existPassword = localStorage.getItem("dexiPassword");
      existPassword = decrypt(existPassword);
      if (existPassword === password || !passRequired) {
        if (location?.state?.checkPassword) {
          let wallet = localStorage.getItem("dexiWallet");
          wallet = JSON.parse(wallet);
          dispatch(setWallets(wallet));
          history.push("/main");
        } else if (location?.state?.importWallet)
          history.push({
            pathname: "/secretphrase",
            state: { importWallet: true },
          });
        else if (location?.state?.createWallet)
          history.push({
            pathname: "/secretphrase",
            state: { createWallet: true },
          });
        else history.push("/secretphrase");
      } else {
        alert("Invalid password");
      }
    } else {
      if (!confirmPassword) {
        alert("Please confirm the password");
        return;
      } else if (confirmPassword !== password) {
        alert("Passwords don't match");
        return;
      }

      let encPass = encrypt(password);
      localStorage.setItem("dexiPassword", encPass);

      if (location?.state?.importWallet)
        history.push({
          pathname: "/secretphrase",
          state: { importWallet: true },
        });
      else if (location?.state?.createWallet)
        history.push({
          pathname: "/secretphrase",
          state: { createWallet: true },
        });
      else history.push("/");
    }
  };
  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "align-items-center"]}
    >
      <>
        <AuthLogoHeader />
        <div
          className={[
            classes["content-wrapper"],
            "d-flex",
            "flex-column",
            "align-items-center",
          ].join(" ")}
        >
          <div
            className={[
              classes["enter-password-wrapper"],
              "d-flex",
              "flex-column",
              "align-items-center",
            ].join(" ")}
          >
            {passwordExists ? (
              <>
                {" "}
                <p className={classes["enter-password-title"]}>
                  Enter Password
                </p>
                <p className={[classes["field-label"], "mb-1"].join(" ")}>
                  Password
                </p>
                <Input
                  type="password"
                  wrapperWidth="100%"
                  width="100%"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </>
            ) : (
              <>
                <p className={classes["enter-password-title"]}>
                  Create Password
                </p>
                <p className={[classes["field-label"], "mb-1"].join(" ")}>
                  New Password
                </p>
                <Input
                  type="password"
                  wrapperWidth="100%"
                  width="100%"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <p
                  className={[classes["field-label"], "mb-1", "mt-4"].join(" ")}
                >
                  New Password
                </p>
                <Input
                  type="password"
                  wrapperWidth="100%"
                  width="100%"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </>
            )}

            <div className="pt-4">
              <BorderButton
                fontSize={14}
                width="13rem"
                onClick={() => {
                  onCreateWallet();
                }}
              >
                Confirm
              </BorderButton>
            </div>
          </div>
          {passwordExists ? (
            <div className="pb-4" style={{ marginTop: "80%" }}>
              <div className="pt-3">
                <BorderButton
                  lightBack
                  fontSize={12}
                  width="16rem"
                  onClick={() => {
                    localStorage.clear();
                    history.push("/");
                  }}
                >
                  Reset Wallet
                </BorderButton>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    </Layout>
  );
};

export default Index;
