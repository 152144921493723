import * as actionTypes from "../actionTypes";
import { encrypt, hunterTokenList } from "dexio-sdk";
import { createBtcWallet } from "../../helpers/createBtcWallet";

export const addWallet = (wallet: Wallet) => (dispatch: any) => {
  const action: any = {
    type: actionTypes.SET_WALLET,
    wallet: wallet,
  };

  if (localStorage.getItem(`balances${wallet.address}`)) {
    let balances = localStorage.getItem(`balances${wallet.address}`) || "{}";
    balances = JSON.parse(balances);

    const balAction: any = {
      type: actionTypes.SET_BALANCES,
      balance: balances,
    };
    dispatch(balAction);
  }

  if (localStorage.getItem(`nfts${wallet.address}`)) {
    let balances = localStorage.getItem(`nfts${wallet.address}`) || "[]";
    balances = JSON.parse(balances);

    const balAction: any = {
      type: actionTypes.SET_NFT_BALANCES,
      balance: balances,
    };
    dispatch(balAction);
  }

  if (localStorage.getItem(`tokens${wallet.address}`)) {
    let tokens = localStorage.getItem(`tokens${wallet.address}`) || "{}";
    tokens = JSON.parse(tokens);

    const balAction: any = {
      type: actionTypes.SET_TOKENS,
      tokens,
    };
    dispatch(balAction);
  }
  dispatch(setActiveWallet(wallet));
  dispatch(action);
};

export const updateWallets = (wallet: Wallet[]) => (dispatch: any) => {
  const action: any = {
    type: actionTypes.UPDATE_WALLETS,
    wallets: wallet,
  };

  dispatch(action);
  localStorage.setItem("dexiWallet", JSON.stringify(wallet));
};

export const setActiveWallet = (wallet: Wallet) => (dispatch: any) => {
  const action: any = {
    type: actionTypes.SET_ACTIVE_WALLET,
    wallet,
  };
  localStorage.setItem("activeWallet", JSON.stringify(wallet));
  if (localStorage.getItem(`balances${wallet.address}`)) {
    let balances = localStorage.getItem(`balances${wallet.address}`) || "{}";
    balances = JSON.parse(balances);

    const balAction: any = {
      type: actionTypes.SET_BALANCES,
      balance: balances,
    };
    dispatch(balAction);
  } else {
    const balAction: any = {
      type: actionTypes.SET_BALANCES,
      balance: [],
    };
    dispatch(balAction);
  }

  if (localStorage.getItem(`tokens${wallet.address}`)) {
    let tokens = localStorage.getItem(`tokens${wallet.address}`) || "{}";
    tokens = JSON.parse(tokens);

    const balAction: any = {
      type: actionTypes.SET_TOKENS,
      tokens,
    };
    dispatch(balAction);
  } else {
    const balAction: any = {
      type: actionTypes.SET_TOKENS,
      tokens: hunterTokenList,
    };
    dispatch(balAction);
  }

  dispatch(action);
};

export const removeWallet =
  (wallet: string, activeWallet: string, wallets: Wallet[]) =>
  (dispatch: any) => {
    const action: any = {
      type: actionTypes.REMOVE_WALLET,
      payload: { wallet, activeWallet },
    };

    dispatch(action);
    let tempWallets = wallets.filter((item) => item.address !== wallet);

    localStorage.setItem("dexiWallet", JSON.stringify(tempWallets));
    if (wallet == activeWallet && tempWallets.length > 0) {
      dispatch(setActiveWallet(tempWallets[0]));
    }
  };

export const setWallets = (wallets: Wallet[]) => (dispatch: any) => {
  const action: any = {
    type: actionTypes.SET_INITIAL_WALLETS,
    wallets,
  };
  let activeWalletItem = localStorage.getItem("activeWallet") || "{}";
  let activeWallet = JSON.parse(activeWalletItem);
  console.log("active wallet: ", activeWallet);
  console.log("wallets: ", wallets);
  if (activeWalletItem !== "{}") {
    console.log("active wallet dispatch");
    dispatch(setActiveWallet(activeWallet));
  } else {
    localStorage.setItem("activeWallet", JSON.stringify(wallets[0]));
    dispatch(setActiveWallet(wallets[0]));
    activeWallet = wallets[0];
  }
  if (localStorage.getItem(`balances${activeWallet.address}`)) {
    let balances =
      localStorage.getItem(`balances${activeWallet.address}`) || "{}";
    balances = JSON.parse(balances);

    const balAction: any = {
      type: actionTypes.SET_BALANCES,
      balance: balances,
    };
    dispatch(balAction);
  }
  if (localStorage.getItem(`tokens${activeWallet.address}`)) {
    let tokens = localStorage.getItem(`tokens${activeWallet.address}`) || "{}";
    tokens = JSON.parse(tokens);

    const balAction: any = {
      type: actionTypes.SET_TOKENS,
      tokens,
    };
    dispatch(balAction);
  }
  dispatch(action);
};

export const setBalances =
  (balances: Balance[], activeWallet: string) => (dispatch: any) => {
    const action: any = {
      type: actionTypes.SET_BALANCES,
      balance: balances,
    };

    localStorage.setItem(`balances${activeWallet}`, JSON.stringify(balances));

    dispatch(action);
  };

export const setNftBalances =
  (nfts: NftToken[], activeWallet: string) => (dispatch: any) => {
    const action = {
      type: actionTypes.SET_NFT_BALANCES,
      nfts: nfts,
    };

    localStorage.setItem(`nfts${activeWallet}`, JSON.stringify(nfts));

    dispatch(action);
  };

export const setTokens = (tokens: Token[]) => (dispatch: any) => {
  const action: any = {
    type: actionTypes.SET_TOKENS,
    tokens,
  };

  dispatch(action);
};

export const setTransactions = (transactions: any[]) => (dispatch: any) => {
  const action: any = {
    type: actionTypes.SET_TRANSACTIONS,
    transactions,
  };

  dispatch(action);
};

export const setActiveNft = (selectedNft: any) => (dispatch: any) => {
  const action: any = {
    type: actionTypes.SET_ACTIVE_NFT,
    selectedNft,
  };

  dispatch(action);
};

export const addToken =
  (token: any, seed: string, address: string, balances: Balance[]) =>
  (dispatch: any) => {
    const action: any = {
      type: actionTypes.ADD_TOKEN,
      token: token,
      wallet: address,
    };

    let tempBalances = [
      {
        ...token,
        balance: 0,
        circulatingSupply: 0,
        decimals: 0,
        delutedMarketCap: 0,
        marketCap: { usd: 0 },
        priceChange24H: 0,
        totalSupply: 0,
        usdBalance: 0,
        usdPrice: 0,
        volume24H: 0,
      },
      ...balances,
    ];

    dispatch(setBalances(tempBalances, address));

    if (token.id == "bitcoin") {
      let wallet = createBtcWallet(seed);
      let encKey = encrypt(wallet.privateKey);
      let encAddress = encrypt(wallet.address);
      localStorage.setItem("dexiBtcKey", encKey);
      localStorage.setItem("dexiBtcAddress", encAddress);
    }
    dispatch(action);
  };
