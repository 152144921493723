import { encrypt, decrypt } from "dexio-sdk";
const bip32 = require("bip32");
const bip39 = require("bip39");
const bitcoin = require("bitcoinjs-lib");
const network = bitcoin.networks.bitcoin;

export const createBtcWallet = (seedVal) => {
  let existPhrase = decrypt(seedVal);
  let mnemonic = existPhrase;

  const seed = bip39.mnemonicToSeedSync(mnemonic);
  let root = bip32.fromSeed(seed, network);
  // const path = `m/49'/0'/0'/0`; // Use m/49'/1'/0'/0 for testnet

  let account = root.derivePath("m/84'/0'/0'");
  let node = account.derive(0).derive(0);

  let btcAddress = bitcoin.payments.p2wpkh({
    pubkey: node.publicKey,
    network: network,
  }).address;

  return {
    address: btcAddress,
    privateKey: node.toWIF(),
    seed: mnemonic,
  };
};
