import React from "react";
import classes from "./style.module.css";
const index = ({
  children,
  fontSize,
  lightBack,
  marginRight,
  width,
  minWidth,
  onClick,
  ...props
}) => {
  return (
    <div
      style={{
        width: "80%",
        margin: "0 auto",
        alignItems: "center",
      }}
      onClick={onClick}
      className={[
        classes["border-button"],
        "px-3 mt-2 ",
        lightBack && classes["light-back"],
      ].join(" ")}
    >
      {children}
    </div>
  );
};

export default index;
