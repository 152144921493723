import React, { useState } from "react";
import BackgroundImage from "../../assets/backgrounds/Account.svg";
import { Header, Layout } from "../../Components";
import { useSelector, useDispatch } from "react-redux";
import classes from "./style.module.css";
import { postSendNft } from "dexio-sdk";
import { setNftBalances } from "store/actions";
import { message } from "antd";

const Index = () => {
  const wallet = useSelector((state) => state.wallet.activeWallet);
  const nftBalances = useSelector((state) => state.balances.nftBalances);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.balances.activeNft);

  const [recipient, setRecipient] = useState("");
  const [isTransfer, setTransfer] = useState(false);
  const [isSending, setSending] = useState(false);

  const handleSend = async () => {
    setSending(true);

    let isSuccess = false;

    try {
      isSuccess = await postSendNft(data, recipient, wallet);
    } catch (e) {
      console.error(e);
    }

    if (isSuccess) {
      message.success("Successfully sent your nft token!");

      dispatch(
        setNftBalances(
          nftBalances.filter(
            (item) =>
              !(
                item.contractAddress == data.contractAddress &&
                item.tokenID == data.tokenID
              )
          ),
          wallet.address
        )
      );
    }

    setSending(false);
  };

  return (
    <Layout
      backgroundImage={BackgroundImage}
      restClasses={["d-flex", "flex-column", "align-items-center"]}
    >
      <>
        <Header title="Nft Detail" varriant={false} />

        <div className="container">
          <div
            className={[classes["asset-list-box"], "py-3", "mt-3"].join(" ")}
          >
            <div className="d-flex justify-content-center">
              {data.metaData?.image ? (
                <img
                  src={data.metaData?.image}
                  alt=""
                  className="img-fluid"
                  style={{ borderRadius: "15px", width: "90%" }}
                />
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{
                    width: "100%",
                    minHeight: "100px",
                    background: "grey",
                  }}
                >
                  Image not available
                </div>
              )}
            </div>

            <div
              className="d-flex justify-content-center mt-4 flex-column"
              style={{ width: "90%", margin: "auto" }}
            >
              {data.metaData?.name && (
                <h3 style={{ color: "white" }} className="mb-3">
                  {data.metaData?.name}{" "}
                </h3>
              )}
              {data.metaData?.description && (
                <p style={{ color: "white" }}>{data.metaData.description}</p>
              )}
              <p style={{ color: "white" }} className="mt-4 text-left">
                {data.metaData?.category
                  .map((item) => (typeof item === "string" ? `#${item}` : ""))
                  .join(" ")}
              </p>

              {isTransfer ? (
                <div className="d-flex justify-content-center my-4 flex-column">
                  <h5 style={{ color: "white", textAlign: "center" }}>
                    Enter Recipient
                  </h5>

                  <div
                    className={[
                      classes["select-token-dropdown"],
                      "d-flex",
                      "align-items-center",
                    ].join(" ")}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ flex: 1 }}
                    >
                      <div
                        className={[classes["input-wrapper"], "d-flex"].join(
                          " "
                        )}
                      >
                        <input
                          className="mb-0"
                          value={recipient}
                          onChange={(e) => setRecipient(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={handleSend}
                    disabled={isSending || !recipient}
                    className={[
                      classes["wallet-button"],
                      "mx-auto",
                      "my-4",
                    ].join(" ")}
                  >
                    {isSending ? "Sending" : "Send"}
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setTransfer(true)}
                  className={[classes["wallet-button"], "mx-auto", "my-4"].join(
                    " "
                  )}
                >
                  Transfer
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Index;
